import os from 'os';
import { isPRODUCTION, isSTAGING, isQA, isDEVELOPMENT } from './environments';

import VERSION from './version';

export const DEFAULT_LOCATIONID = 378;
export const LOGIN_TAPP_STAGING_URL = 'https://login.chayns.net/staging/v3/index.html?AppVersion=2&SiteID=60021-08989&color=005eb8&noBackground=0&qrLogin=1&staticMode=1&sso_exclude=6&teamRuntime=1';
export const LOGIN_TAPP_LIVE_URL = 'https://login.chayns.net/v3/index.html?AppVersion=2&SiteID=60021-08989&color=005eb8&noBackground=0&qrLogin=1&staticMode=1&sso_exclude=6&teamRuntime=1';

export const SITES_URL = 'https://cube.tobit.cloud/springboard/api/';

export const NEWS_URL = 'https://mashup.tobit.com/api/news/v3.0/';

export const USER_AGENT = `tobit-team-runtime-node/${VERSION} (${os.hostname()}; ${os.type()} ${os.release()}) node-fetch ${(isSTAGING || isQA || isDEVELOPMENT) ? 'internal' : ''}`;

export const CRAWLER_URL = 'https://cube.tobit.cloud/preview-crawler/api/v1.0/';

export const QR_CODE_GENERATOR_URL = 'https://cube.tobit.cloud/qr-code-generator/v1.0/';

export const MIN_CALENDAR_API_VERSION = '1.1.212';

export const CHAYNS_JS_URL = isPRODUCTION ? 'https://api.chayns-static.space/js/v4.0/chayns.min.js' : 'https://api.chayns-static.space/js/staging/v4.0/chayns.min.js';

export const DIALOG_JS_URL = isPRODUCTION ? 'https://chayns-res.tobit.com/API/v3.1/dialog/js/dialog.js' : 'https://chayns-res.tobit.com/API/v3.1/intern/qa/dialog/js/dialog.min.js';

export const LANG_RES_URL = 'https://api.chayns-static.space/lang/v4.0/js/chaynsLangRes.min.js';

export const INTERCOM_API_URL = 'https://sub54.tobit.com/rest/api/';

export const prefix = isQA ? '-dev' : isSTAGING ? '-staging' : isDEVELOPMENT ? '-staging' : '';

const CALENDAR_URL = isDEVELOPMENT
    ? 'https://w-ljesussek-zb.tobit.ag:8083/remoteEntry.js'
    : `https://tapp${prefix}.chayns-static.space/david-calendar/v1/remoteEntry.js`;
export const CalendarSystem = {
    scope: 'david_calendar',
    url: CALENDAR_URL,
    module: './AppWrapper',
};

const SMART_CLIENT_URL = isDEVELOPMENT
    ? 'https://w-ljesussek-zb.tobit.ag:8082/remoteEntry.js'
    : `https://tapp${prefix}.chayns-static.space/davidrestapifrontend/v2.1/remoteEntry.js`;
export const SmartClientSystem = {
    scope: 'david_smartclient',
    url: SMART_CLIENT_URL,
    module: './AppWrapper',
};

const INTERCOM_THREAD_VIEW_URL = isDEVELOPMENT
    ? `https://tapp${prefix}.chayns-static.space/intercom/v3.1/remoteEntry.js` // ? 'https://w-ljesussek-zb.tobit.ag:8081/remoteEntry.js'
    : `https://tapp${prefix}.chayns-static.space/intercom/v3.1/remoteEntry.js`;
export const IntercomThreadViewSystem = {
    scope: 'chayns_intercom',
    url: INTERCOM_THREAD_VIEW_URL,
    module: './SpringboardThreadView',
};

const INTERCOM_WEBSOCKET_VIEW_URL = isDEVELOPMENT
    ? `https://tapp${prefix}.chayns-static.space/intercom/v3.1/remoteEntry.js` // ? 'https://w-ljesussek-zb.tobit.ag:8080/remoteEntry.js'
    : `https://tapp${prefix}.chayns-static.space/intercom/v3.1/remoteEntry.js`;
export const IntercomWebsocketView = {
    scope: 'chayns_intercom',
    url: INTERCOM_WEBSOCKET_VIEW_URL,
    module: './WebsocketView',
};

const WALLET_HOT_CARDS_SYSTEM_URL = isDEVELOPMENT
    ? 'https://tapp.chayns-static.space/wallet/v3/remoteEntry.js' //  ? 'https://w-mgertdenken-l.tobit.ag:8080/remoteEntry.js'
    : 'https://tapp.chayns-static.space/wallet/v3/remoteEntry.js';
export const WalletHotCardsSystem = {
    scope: 'wallet_v2',
    url: WALLET_HOT_CARDS_SYSTEM_URL,
    module: './Wallet',
};
